import React from "react";

export function EditIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M22 9.979V16h-6v6H2V3a1 1 0 0 1 1-1h13.148L17.359.646A4.934 4.934 0 0 1 18.07 0H3a3 3 0 0 0-3 3v21h18.414L24 18.414V7.979Zm-4 11.607V18h3.586Z" />
                <path d="m12.729 11.82 7.611-8.507a.945.945 0 0 1 1.372-.038.943.943 0 0 1 0 1.335l-8.032 8.033a4.964 4.964 0 0 1 1.042 1.785l8.4-8.4a2.947 2.947 0 0 0 0-4.163 2.943 2.943 0 0 0-4.276.118l-8.136 9.093a4.967 4.967 0 0 1 2.019.744ZM10 13c-4 0-5 6-5 6h5a3 3 0 0 0 0-6Z" />
            </g>
        </svg>
    );
}
