import styled from "styled-components";
import { InputProps } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledTextInputPropsType = {
    type:
        | "text"
        | "email"
        | "password"
        | "date"
        | "datetime-local"
        | "month"
        | "number"
        | "search"
        | "tel"
        | "time"
        | "url"
        | "week"
        | "textarea";
    placeholder: string;
} & InputProps;

export const StyledTextInput = styled(AvField)<StyledTextInputPropsType>`
    font-family: "Segoe UI";
    color: #2b2828;
    font-size: 16px;
    border-radius: 6px;
    padding: 2px 8px;
`;
