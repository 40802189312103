import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import styled from "styled-components";
import { uid } from "react-uid";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

export type TabsType = {
    title: React.ReactNode;
    content: React.ReactNode;
    disabled?: boolean;
};

export type TabsPropsType = { tabs: TabsType[] };

export function Tabs({ tabs }: TabsPropsType) {
    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleActiveTab(tab: number): void {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const StyleNavLink = styled(NavLink)`
        color: #323333;
        &:hover {
            color: #323333;
        }
    `;

    return (
        <React.Fragment>
            <Nav tabs>
                {tabs.map((element: TabsType, index: number) => {
                    return (
                        <NavItem
                            className={classNames("nav-item-hover__clz", {
                                "nav-item-active__clz": activeTab === index,
                            })}
                            key={uid(element, index)}
                        >
                            <StyleNavLink
                                className={classNames(
                                    "disable-nav-link-hover",
                                    {
                                        "active nav-link-new-segment-active__clz active-tab__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleActiveTab(index);
                                }}
                                disabled={element.disabled}
                            >
                                {element.title}
                            </StyleNavLink>
                        </NavItem>
                    );
                })}
            </Nav>
            <TabContent activeTab={activeTab} className="m-3">
                {tabs.map((element: TabsType, index: number) => {
                    return (
                        <TabPane tabId={index} key={uid(element, index)}>
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </React.Fragment>
    );
}
