import React from "react";

export function SquareIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path d="M24 24H0V0h24ZM2 22h20V2H2Z" data-name="01 align center" />
        </svg>
    );
}
