import React from "react";
import styled from "styled-components";

type SearchType =
    | {
          search: true;
          onChange: () => void;
      }
    | {
          search: false;
      };

export type HeaderPropsType = SearchType & {
    brand: React.ReactNode;
    icons: string[];
    menuIcon: boolean;
};

export const HeaderWrapper = styled.header`
    z-index: 1002;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    width: 100%;
    .navbar-header {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        height: 70px;
        padding: 0 calc(24px / 2) 0 0;
        background-color: white;
    }
    .navbar-brand-box {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #292929;
        height: 70px;
    }
    .form-control {
        display: block;
        width: 100%;
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border: none;
        height: 38px;
        padding-left: 40px;
        padding-right: 20px;
        background-color: #f3f3f9;
        box-shadow: none;
        border-radius: 30px;
    }

    .border-none__clz {
        &:hover {
            border: none;
        }
    }
`;

export const SearchBoxWrapper = styled.div`
    display: flex;

    & > button:hover {
        border: unset;
    }

    & > label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
`;
