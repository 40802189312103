import React from "react";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledH1PropsType = {
    children: React.ReactNode;
};

export const StyledH1 = styled.h1<StyledH1PropsType>`
    font-family: "Segoe UI Semibold";
    color: #323333;
    font-size: 36px;
`;
