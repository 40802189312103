import React from "react";

export function ShareIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            data-name="01 align center"
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <path
                data-name="Trac\xE9 17816"
                d="M1.693 19.968H0v-6.775a7.633 7.633 0 0 1 7.621-7.625l4.1-.131V0l7.872 7.875a2.542 2.542 0 0 1 0 3.595l-7.868 7.875v-5.436l-4.106.131a5.937 5.937 0 0 0-5.926 5.928Zm5.932-7.627 5.8-.131v3.039l4.977-4.981a.847.847 0 0 0 0-1.2L13.42 4.091V7.13l-5.8.131a5.937 5.937 0 0 0-5.927 5.932v1.991a7.61 7.61 0 0 1 5.932-2.838Z"
                fill="#2b2828"
            />
        </svg>
    );
}
