import React from "react";

export function InfoIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12Zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2Z" />
                <path d="M14 19h-2v-7h-2v-2h2a2 2 0 0 1 2 2Z" />
                <circle cx={12} cy={6.5} r={1.5} />
            </g>
        </svg>
    );
}
