import React from "react";

export function AngleDoubleSmallLeftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M11.189 6.707 9.775 5.293l-5.293 5.293a2 2 0 0 0 0 2.828l5.293 5.293 1.414-1.414L5.9 12Z" />
                <path d="m18.189 6.707-1.414-1.414-6 6a1 1 0 0 0 0 1.414l6 6 1.414-1.414L12.9 12Z" />
            </g>
        </svg>
    );
}
