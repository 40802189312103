import React from "react";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledParagraphPropsType = {
    children: React.ReactNode;
};

export const StyledParagraph = styled.p<StyledParagraphPropsType>`
    font-family: "Segoe UI Semibold";
    color: #2b2828;
    font-size: 16px;
`;
