import React from "react";

export function MenuIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24.704}
            height={24.655}
            {...props}
        >
            <path d="M22.388 24.656h-6.434a2.319 2.319 0 0 1-2.316-2.316v-6.434a2.319 2.319 0 0 1 2.316-2.316h6.434a2.319 2.319 0 0 1 2.312 2.316v6.434a2.318 2.318 0 0 1-2.312 2.316Zm-6.434-9.522a.772.772 0 0 0-.772.772v6.434a.772.772 0 0 0 .772.772h6.434a.772.772 0 0 0 .772-.772v-6.434a.772.772 0 0 0-.772-.772Zm-5.806 9.489H.772a.772.772 0 1 1 0-1.544h9.376a.772.772 0 1 1 0 1.544Zm0-4.672H.772a.772.772 0 1 1 0-1.544h9.376a.772.772 0 0 1 0 1.544Zm.146-4.672H.772a.772.772 0 1 1 0-1.544h9.522a.772.772 0 0 1 0 1.544Zm12.094-4.213h-6.434a2.319 2.319 0 0 1-2.316-2.316V2.316A2.319 2.319 0 0 1 15.954 0h6.434A2.319 2.319 0 0 1 24.7 2.316V8.75a2.319 2.319 0 0 1-2.312 2.316Zm-6.434-9.522a.772.772 0 0 0-.772.772V8.75a.772.772 0 0 0 .772.772h6.434a.772.772 0 0 0 .772-.772V2.316a.772.772 0 0 0-.772-.772ZM8.75 11.066H2.316A2.319 2.319 0 0 1 0 8.75V2.316A2.319 2.319 0 0 1 2.316 0H8.75a2.319 2.319 0 0 1 2.316 2.316V8.75a2.319 2.319 0 0 1-2.316 2.316ZM2.316 1.544a.772.772 0 0 0-.772.772V8.75a.772.772 0 0 0 .772.772H8.75a.772.772 0 0 0 .772-.772V2.316a.772.772 0 0 0-.772-.772Z" />
        </svg>
    );
}
