import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

type BreadcrumbItems = {
    item: string;
    link: string;
};

export type StyledBreadcrumbPropsType = {
    items?: BreadcrumbItems[];
};

export function StyledBreadcrumb({ items }: StyledBreadcrumbPropsType) {
    const lastElementOfBreadcrumbItems: number = items!.length - 1;

    return (
        <Breadcrumb listTag="div">
            {items!.map((element: BreadcrumbItems, index: number) => {
                return (
                    <BreadcrumbItem
                        active={lastElementOfBreadcrumbItems === index}
                        href={element.link}
                        tag={
                            lastElementOfBreadcrumbItems !== index
                                ? "a"
                                : "span"
                        }
                        key={index}
                    >
                        {element.item}
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
}
