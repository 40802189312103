export const black = "#000000";
export const white = "#FFFFFF";

export const COLORS: { [key: string]: string } = {
    background: "#F8F8FB",
    principal: "#323333",
    secondary: "#626368",
    disabledButtonBorder: "#C6C6C6",
    disabled: "#EFEFEF",
    white: "#FFFFFF",
    text: "#2B2828",
    danger: "#E30613",
    reminder: "#AE0F0A",
    success: "#34C38F",
    info: "#0077D7",
    warning: "#F1B44C",
};
