import React from "react";

export function AngleDoubleSmallRightIcon(
    props: React.SVGProps<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="m19.1 10.586-5.289-5.293L12.4 6.707 17.689 12 12.4 17.293l1.415 1.414 5.285-5.293a2 2 0 0 0 0-2.828Z" />
                <path d="m12.811 11.293-6-6L5.4 6.707 10.689 12 5.4 17.293l1.415 1.414 6-6a1 1 0 0 0-.004-1.414Z" />
            </g>
        </svg>
    );
}
