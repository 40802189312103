import styled from "styled-components";
import Slider from "rc-slider";

export type StyledSliderPropsType = {
    min: number;
    max: number;
    step: number;
} & typeof Slider;

export const StyledSlider: any = styled(Slider)<StyledSliderPropsType>`
    box-sizing: border-box;
    &.rc-slider {
        position: relative;
        height: 14px;
        padding: 5px 0;
        width: 100%;
        border-radius: 6px;
        touch-action: none;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-rail {
        position: absolute;
        width: 100%;
        background-color: #e9e9e9;
        height: 4px;
        border-radius: 6px;
    }
    .rc-slider-track {
        left: 0%;
        right: auto;
        width: 15%;
        position: absolute;
        height: 4px;
        border-radius: 6px;
        background-color: #abe2fb;
    }
    .rc-slider-step {
        position: absolute;
        width: 100%;
        height: 4px;
        background: transparent;
    }
    .rc-slider-handle {
        left: 15%;
        right: auto;
        transform: translateX(-50%);
        position: absolute;
        width: 14px;
        height: 14px;
        margin-top: -5px;
        cursor: grab;
        border-radius: 50%;
        border: 2px solid #96dbfa;
        background-color: #fff;
        touch-action: pan-x;
    }
    .rc-slider-mark {
        position: absolute;
        top: 18px;
        left: 0;
        width: 100%;
        font-size: 12px;
    }
`;
