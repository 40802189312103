import React from "react";

export function SignInIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M2 21V3a1 1 0 0 1 1-1h5V0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h5v-2H3a1 1 0 0 1-1-1Z" />
                <path d="M24 13v-2l-16.444.031 4.323-4.324-1.416-1.414-4.586 4.586a3 3 0 0 0 0 4.242l4.586 4.586 1.414-1.414-4.263-4.263Z" />
            </g>
        </svg>
    );
}
