import React from "react";

export function ModuleIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={50}
            height={50}
            {...props}
        >
            <g>
                <path
                    data-name="Trac\xE9 18505"
                    d="M2.055 14.382a2.055 2.055 0 1 1 2.055-2.055 2.057 2.057 0 0 1-2.055 2.055Zm0-3.287a1.233 1.233 0 1 0 1.233 1.233 1.234 1.234 0 0 0-1.233-1.233Z"
                />
                <path
                    data-name="Trac\xE9 18506"
                    d="M22.601 14.382a2.055 2.055 0 1 1 2.055-2.055 2.057 2.057 0 0 1-2.055 2.055Zm0-3.287a1.233 1.233 0 1 0 1.233 1.233 1.234 1.234 0 0 0-1.233-1.233Z"
                />
                <path
                    data-name="Trac\xE9 18507"
                    d="M12.328 24.655a2.055 2.055 0 1 1 2.055-2.055 2.057 2.057 0 0 1-2.055 2.055Zm0-3.287a1.233 1.233 0 1 0 1.233 1.233 1.234 1.234 0 0 0-1.233-1.233Z"
                />
                <path
                    data-name="Trac\xE9 18508"
                    d="M12.328 4.109a2.055 2.055 0 1 1 2.055-2.055 2.057 2.057 0 0 1-2.055 2.055Zm0-3.287a1.233 1.233 0 1 0 1.233 1.233A1.234 1.234 0 0 0 12.328.822Z"
                />
                <path
                    data-name="Trac\xE9 18509"
                    d="M22.47 11.108a.411.411 0 0 1-.405-.346 9.816 9.816 0 0 0-.65-2.271.411.411 0 0 1 .757-.321 10.609 10.609 0 0 1 .705 2.462.411.411 0 0 1-.341.471.389.389 0 0 1-.065.005Z"
                />
                <path
                    data-name="Trac\xE9 18510"
                    d="M20.462 6.465a.41.41 0 0 1-.325-.16 9.928 9.928 0 0 0-1.79-1.789.411.411 0 1 1 .5-.65 10.741 10.741 0 0 1 1.937 1.937.411.411 0 0 1-.325.662Z"
                />
                <path
                    data-name="Trac\xE9 18511"
                    d="M16.325 3.274a.407.407 0 0 1-.16-.032 9.814 9.814 0 0 0-2.271-.651.411.411 0 1 1 .129-.812 10.624 10.624 0 0 1 2.462.706.411.411 0 0 1-.16.789Z"
                />
                <path
                    data-name="Trac\xE9 18512"
                    d="M13.958 22.881a.411.411 0 0 1-.064-.817 9.814 9.814 0 0 0 2.271-.651.411.411 0 0 1 .321.757 10.624 10.624 0 0 1-2.462.706.405.405 0 0 1-.066.005Z"
                />
                <path
                    data-name="Trac\xE9 18513"
                    d="M18.601 20.873a.411.411 0 0 1-.251-.736 9.93 9.93 0 0 0 1.789-1.79.411.411 0 1 1 .65.5 10.717 10.717 0 0 1-1.937 1.938.408.408 0 0 1-.251.088Z"
                />
                <path
                    data-name="Trac\xE9 18514"
                    d="M21.792 16.735a.411.411 0 0 1-.378-.571 9.8 9.8 0 0 0 .65-2.271.411.411 0 1 1 .812.129 10.623 10.623 0 0 1-.705 2.462.411.411 0 0 1-.379.251Z"
                />
                <path
                    data-name="Trac\xE9 18515"
                    d="M2.863 16.735a.411.411 0 0 1-.378-.251 10.608 10.608 0 0 1-.705-2.462.411.411 0 1 1 .812-.129 9.816 9.816 0 0 0 .65 2.271.411.411 0 0 1-.218.539.418.418 0 0 1-.161.032Z"
                />
                <path
                    data-name="Trac\xE9 18516"
                    d="M6.057 20.875a.408.408 0 0 1-.251-.086 10.741 10.741 0 0 1-1.937-1.937.411.411 0 0 1 .65-.5 9.928 9.928 0 0 0 1.79 1.789.411.411 0 0 1-.251.736Z"
                />
                <path
                    data-name="Trac\xE9 18517"
                    d="M10.697 22.881a.4.4 0 0 1-.065-.005 10.624 10.624 0 0 1-2.462-.706.411.411 0 0 1 .321-.757 9.814 9.814 0 0 0 2.271.651.411.411 0 0 1-.065.817Z"
                />
                <path
                    data-name="Trac\xE9 18518"
                    d="M8.331 3.274a.411.411 0 0 1-.16-.789 10.624 10.624 0 0 1 2.462-.706.411.411 0 1 1 .129.812 9.814 9.814 0 0 0-2.271.651.416.416 0 0 1-.16.032Z"
                />
                <path
                    data-name="Trac\xE9 18519"
                    d="M4.191 6.468a.411.411 0 0 1-.325-.662 10.717 10.717 0 0 1 1.937-1.938.411.411 0 1 1 .5.65 9.93 9.93 0 0 0-1.789 1.79.41.41 0 0 1-.323.16Z"
                />
                <path
                    data-name="Trac\xE9 18520"
                    d="M2.185 11.108a.393.393 0 0 1-.065-.005.411.411 0 0 1-.341-.471 10.623 10.623 0 0 1 .705-2.462.411.411 0 1 1 .757.321 9.8 9.8 0 0 0-.65 2.271.41.41 0 0 1-.405.346Z"
                />
                <path
                    data-name="Trac\xE9 18521"
                    d="M9.04 13.971a.413.413 0 0 1-.184-.043l-3.287-1.644a.411.411 0 0 1-.227-.368V8.63a.411.411 0 0 1 .595-.368l3.287 1.644a.411.411 0 0 1 .227.368v3.287a.41.41 0 0 1-.411.411Zm-2.876-2.309 2.466 1.233v-2.368L6.164 9.294Z"
                />
                <path
                    data-name="Trac\xE9 18522"
                    d="M9.04 13.971a.41.41 0 0 1-.411-.411v-3.287a.411.411 0 0 1 .227-.368l3.287-1.644a.411.411 0 0 1 .595.368v3.29a.411.411 0 0 1-.227.368l-3.287 1.644a.413.413 0 0 1-.184.04Zm.411-3.444v2.369l2.466-1.233V9.294Z"
                />
                <path
                    data-name="Trac\xE9 18523"
                    d="M9.04 10.684a.411.411 0 0 1-.184-.043L5.569 8.997a.411.411 0 0 1 0-.736l3.287-1.644a.411.411 0 0 1 .368 0l3.288 1.644a.411.411 0 0 1 0 .736l-3.287 1.644a.416.416 0 0 1-.185.043ZM6.672 8.629l2.369 1.184 2.371-1.184L9.04 7.445Z"
                />
                <path
                    data-name="Trac\xE9 18524"
                    d="M15.615 13.971a.413.413 0 0 1-.184-.043l-3.287-1.644a.411.411 0 0 1-.227-.368V8.63a.411.411 0 0 1 .595-.368l3.287 1.644a.411.411 0 0 1 .227.368v3.287a.41.41 0 0 1-.411.411Zm-2.876-2.309 2.466 1.233v-2.368l-2.466-1.233Z"
                />
                <path
                    data-name="Trac\xE9 18525"
                    d="M15.615 13.971a.41.41 0 0 1-.411-.411v-3.287a.411.411 0 0 1 .227-.368l3.287-1.644a.411.411 0 0 1 .595.368v3.29a.411.411 0 0 1-.227.368l-3.287 1.644a.413.413 0 0 1-.184.04Zm.411-3.444v2.369l2.466-1.233V9.294Z"
                />
                <path
                    data-name="Trac\xE9 18526"
                    d="M15.615 10.684a.411.411 0 0 1-.184-.043l-3.287-1.644a.411.411 0 0 1 0-.736l3.287-1.644a.411.411 0 0 1 .368 0l3.288 1.644a.411.411 0 0 1 0 .736L15.8 10.641a.416.416 0 0 1-.185.043Zm-2.368-2.055 2.369 1.184 2.371-1.184-2.372-1.184Z"
                />
                <path
                    data-name="Trac\xE9 18527"
                    d="M12.327 18.902a.413.413 0 0 1-.184-.043l-3.287-1.644a.411.411 0 0 1-.227-.368v-3.286a.411.411 0 0 1 .595-.368l3.287 1.644a.411.411 0 0 1 .227.368v3.287a.41.41 0 0 1-.411.411Zm-2.876-2.309 2.466 1.233v-2.368l-2.466-1.233Z"
                />
                <path
                    data-name="Trac\xE9 18528"
                    d="M12.328 18.902a.41.41 0 0 1-.411-.411v-3.287a.411.411 0 0 1 .227-.368l3.287-1.644a.411.411 0 0 1 .595.368v3.29a.411.411 0 0 1-.227.368l-3.287 1.644a.413.413 0 0 1-.184.043Zm.411-3.444v2.369l2.466-1.233v-2.369Z"
                />
                <path
                    data-name="Trac\xE9 18529"
                    d="M12.327 15.615a.412.412 0 0 1-.184-.043l-3.287-1.644a.411.411 0 0 1 0-.736l3.287-1.644a.411.411 0 0 1 .368 0l3.288 1.644a.411.411 0 0 1 0 .736l-3.287 1.644a.416.416 0 0 1-.185.043ZM9.959 13.56l2.369 1.184 2.371-1.184-2.372-1.184Z"
                />
            </g>
        </svg>
    );
}
