import * as React from "react";

export function WorkflowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27739"
                data-name="Groupe 27739"
                transform="translate(18137 4331)"
            >
                <g
                    id="Groupe_27739-2"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                    />
                    <g
                        id="process_1_"
                        data-name="process (1)"
                        transform="translate(5.954 6.007)"
                    >
                        <path
                            id="Trac\xE9_9479"
                            data-name="Trac\xE9 9479"
                            d="M22.171,52.178a2.148,2.148,0,0,0,2.147-1.79h3.309l-.4.386.439.422.931-.9a.29.29,0,0,0,0-.422l-.931-.9-.439.422.4.386H24.318a2.165,2.165,0,1,0-2.147,2.387Zm0-3.581a1.493,1.493,0,1,1-1.551,1.492A1.524,1.524,0,0,1,22.171,48.6Z"
                            transform="translate(-14.416 -34.273)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_9480"
                            data-name="Trac\xE9 9480"
                            d="M50.171,52.178A2.09,2.09,0,1,0,48,50.089,2.134,2.134,0,0,0,50.171,52.178Zm0-3.581a1.493,1.493,0,1,1-1.551,1.492A1.524,1.524,0,0,1,50.171,48.6Z"
                            transform="translate(-33.73 -34.274)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_9481"
                            data-name="Trac\xE9 9481"
                            d="M8.514,25a2.148,2.148,0,0,0-2.147,1.79h-.49a3.733,3.733,0,1,0,0,7.46h.647l-.4.386.439.422.931-.9a.29.29,0,0,0,0-.422l-.931-.9-.439.422.4.386H5.878a3.136,3.136,0,1,1,0-6.266h.49A2.165,2.165,0,1,0,8.514,25Zm0,3.581a1.493,1.493,0,1,1,1.551-1.492A1.524,1.524,0,0,1,8.514,28.581Z"
                            transform="translate(-2 -18.137)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_9482"
                            data-name="Trac\xE9 9482"
                            d="M9.664,2.983,9.226,3.4l.4.386H6.318A2.148,2.148,0,0,0,4.171,2,2.134,2.134,0,0,0,2,4.089,2.134,2.134,0,0,0,4.171,6.178a2.148,2.148,0,0,0,2.147-1.79H9.627l-.4.386.439.422.931-.9a.29.29,0,0,0,0-.422Zm-5.493,2.6A1.524,1.524,0,0,1,2.62,4.089,1.524,1.524,0,0,1,4.171,2.6,1.524,1.524,0,0,1,5.723,4.089,1.524,1.524,0,0,1,4.171,5.581Z"
                            transform="translate(-2 -2)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_9483"
                            data-name="Trac\xE9 9483"
                            d="M39.927,7.52a3.81,3.81,0,0,0-3.878-3.73H34.318a2.177,2.177,0,0,0-4.318.3,2.177,2.177,0,0,0,4.318.3h1.731A3.2,3.2,0,0,1,39.306,7.52a3.2,3.2,0,0,1-3.257,3.133h-4.99l.4-.386-.439-.422-.931.9a.29.29,0,0,0,0,.422l.931.9.439-.422-.4-.386h4.99A3.81,3.81,0,0,0,39.927,7.52Zm-7.755-1.94A1.524,1.524,0,0,1,30.62,4.089a1.552,1.552,0,0,1,3.1,0A1.524,1.524,0,0,1,32.171,5.581Z"
                            transform="translate(-21.314 -2)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
