import React from "react";

export function DiskIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M19 0H3a3 3 0 0 0-3 3v21h24V5ZM7 2h10v4H7Zm15 20H2V3a1 1 0 0 1 1-1h2v6h14V2.828l3 3Z" />
                <path d="M12 10a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z" />
            </g>
        </svg>
    );
}
