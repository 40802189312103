import React from "react";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledTableHeadTdPropsType = {
    children: React.ReactNode;
};

export const StyledTableHeadTd = styled.thead<StyledTableHeadTdPropsType>`
    & td {
        font-family: "Segoe UI Semibold";
        color: #2b2828;
        font-size: 16px;
    }
`;
