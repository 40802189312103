import React from "react";

export function DocumentIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={512}
            viewBox="0 0 24 24"
            width={512}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            {...props}
        >
            <path d="M7 12h10v2H7zm0 6h7v-2H7zM22 7.586V24H2V3a3 3 0 0 1 3-3h9.414zM15 7h3.586L15 3.414zm5 15V9h-7V2H5a1 1 0 0 0-1 1v19z" />
        </svg>
    );
}
