import React from "react";

export function LinkIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M10.583 20.529a5.028 5.028 0 0 1-7.111-7.109l4.262-4.265-1.415-1.414-4.261 4.265A7.027 7.027 0 0 0 12 21.943l4.262-4.262-1.414-1.414ZM21.944 2.061A6.979 6.979 0 0 0 16.975 0a6.983 6.983 0 0 0-4.968 2.057L7.74 6.32l1.414 1.415 4.265-4.262A4.993 4.993 0 0 1 16.973 2a5.028 5.028 0 0 1 3.554 8.583l-4.262 4.262 1.415 1.415L21.942 12a7.037 7.037 0 0 0 .002-9.939Z" />
                <path d="m7.74 14.846 7.105-7.104 1.414 1.414-7.104 7.104z" />
            </g>
        </svg>
    );
}
