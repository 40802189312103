import React from "react";

export function OppositeArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15.436}
            height={19.756}
            viewBox="0 0 15.436 19.756"
            {...props}
        >
            <path
                id="Icon_awesome-exchange-alt"
                data-name="Icon awesome-exchange-alt"
                d="M0,4.322V3.705a.926.926,0,0,1,.926-.926H14.817V.927A.927.927,0,0,1,16.4.272l3.087,3.087a.926.926,0,0,1,0,1.31L16.4,7.755A.927.927,0,0,1,14.817,7.1V5.249H.926A.926.926,0,0,1,0,4.322Zm18.83,5.865H4.939V8.335a.927.927,0,0,0-1.581-.655L.271,10.767a.926.926,0,0,0,0,1.31l3.087,3.087a.927.927,0,0,0,1.581-.655V12.657H18.83a.926.926,0,0,0,.926-.926v-.617A.926.926,0,0,0,18.83,10.187Z"
                transform="translate(15.436) rotate(90)"
            />
        </svg>
    );
}
