import * as React from "react";

export function TagIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27740"
                data-name="Groupe 27740"
                transform="translate(18137 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                    />
                    <g
                        id="box_1_"
                        data-name="box (1)"
                        transform="translate(5 4.999)"
                    >
                        <g
                            id="Groupe_10138"
                            data-name="Groupe 10138"
                            transform="translate(0 0)"
                        >
                            <g id="Groupe_10137" data-name="Groupe 10137">
                                <path
                                    id="Trac\xE9_9456"
                                    data-name="Trac\xE9 9456"
                                    d="M35.109,4.53,25.786.029a.3.3,0,0,0-.262,0L16.2,4.53a.3.3,0,0,0-.17.271v10.93A.3.3,0,0,0,16.2,16l9.323,4.5a.3.3,0,0,0,.262,0L35.109,16a.3.3,0,0,0,.17-.271V4.8A.3.3,0,0,0,35.109,4.53ZM25.656.634,34.287,4.8l-2.5,1.208a.3.3,0,0,0-.051-.032L23.161,1.838ZM22.482,2.178,31.1,6.339l-1.765.852L20.721,3.032ZM31.3,6.91v3.15l-1.649.8V7.706Zm3.376,8.633-8.721,4.21V9.49l2.08-1a.3.3,0,1,0-.262-.542l-2.12,1.023-.834-.4a.3.3,0,0,0-.262.542l.795.384V19.753l-8.721-4.21V5.28l6.641,3.206a.3.3,0,0,0,.262-.542L17.025,4.8l2.988-1.442L29.048,7.72l0,.005v3.609a.3.3,0,0,0,.432.271l2.25-1.086a.3.3,0,0,0,.17-.271V6.619L34.678,5.28V15.542Z"
                                    transform="translate(-16.032 0.001)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                        <g
                            id="Groupe_10140"
                            data-name="Groupe 10140"
                            transform="translate(1.284 13.686)"
                        >
                            <g
                                id="Groupe_10139"
                                data-name="Groupe 10139"
                                transform="translate(0)"
                            >
                                <path
                                    id="Trac\xE9_9457"
                                    data-name="Trac\xE9 9457"
                                    d="M49.848,341.952l-1.368-.66a.3.3,0,1,0-.262.542l1.368.66a.3.3,0,0,0,.262-.542Z"
                                    transform="translate(-48.048 -341.261)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                        <g
                            id="Groupe_10142"
                            data-name="Groupe 10142"
                            transform="translate(1.286 12.259)"
                        >
                            <g id="Groupe_10141" data-name="Groupe 10141">
                                <path
                                    id="Trac\xE9_9458"
                                    data-name="Trac\xE9 9458"
                                    d="M51.153,306.988l-2.625-1.267a.3.3,0,0,0-.262.542l2.625,1.267a.3.3,0,0,0,.262-.542Z"
                                    transform="translate(-48.096 -305.69)"
                                    fill="#fff"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
