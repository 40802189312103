import React from "react";

export function MultiEditIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="content"
            xmlns="http://www.w3.org/2000/svg"
            width={18.302}
            height={18.397}
            viewBox="0 0 18.302 18.397"
            {...props}
        >
            <path
                id="Trac\xE9_21056"
                data-name="Trac\xE9 21056"
                d="M33.483,21.44H23.113a.917.917,0,0,0-.916.916v10.37a.917.917,0,0,0,.916.916h10.37a.917.917,0,0,0,.916-.916V22.356A.917.917,0,0,0,33.483,21.44Zm.028,11.285a.028.028,0,0,1-.028.028H23.113a.028.028,0,0,1-.028-.028V22.356a.028.028,0,0,1,.028-.028h10.37a.028.028,0,0,1,.028.028Z"
                transform="translate(-16.097 -15.244)"
            />
            <path
                id="Trac\xE9_21057"
                data-name="Trac\xE9 21057"
                d="M12.27,22.663V12.3a.028.028,0,0,1,.03-.03H22.666a.028.028,0,0,1,.03.03v1.32h.888V12.3a.918.918,0,0,0-.917-.917H12.3a.918.918,0,0,0-.917.917V22.663a.918.918,0,0,0,.917.917h1.543v-.888H12.3A.028.028,0,0,1,12.27,22.663Z"
                transform="translate(-8.181 -8.403)"
            />
            <path
                id="Trac\xE9_21058"
                data-name="Trac\xE9 21058"
                d="M1.45,12.6V2.233a.029.029,0,0,1,.03-.027h10.37a.026.026,0,0,1,.027.027V3.556h.888V2.233a.915.915,0,0,0-.914-.914H1.479a.917.917,0,0,0-.917.914V12.6a.918.918,0,0,0,.917.917H3.024v-.888H1.479a.031.031,0,0,1-.03-.03Z"
                transform="translate(-0.562 -1.319)"
            />
            <g
                id="Groupe_27803"
                data-name="Groupe 27803"
                transform="translate(8.45 8.093)"
            >
                <g
                    id="Groupe_20931"
                    data-name="Groupe 20931"
                    transform="translate(0)"
                >
                    <g
                        id="Groupe_20930"
                        data-name="Groupe 20930"
                        transform="translate(0 0.78)"
                    >
                        <path
                            id="Trac\xE9_18440"
                            data-name="Trac\xE9 18440"
                            d="M156.367,37.484l4.641-5.156,1.5,1.663-4.641,5.156Zm0,0"
                            transform="translate(-155.784 -32.328)"
                        />
                        <path
                            id="Trac\xE9_18441"
                            data-name="Trac\xE9 18441"
                            d="M132.543,198.86l1.543-.454-1.116-1.186Zm0,0"
                            transform="translate(-132.543 -191.425)"
                        />
                    </g>
                    <path
                        id="Trac\xE9_18442"
                        data-name="Trac\xE9 18442"
                        d="M318.122.534a.842.842,0,0,0-1.227,0l-.368.385,1.635,1.711.368-.385a.939.939,0,0,0,0-1.284Zm0,0"
                        transform="translate(-311.279 -0.269)"
                    />
                </g>
            </g>
        </svg>
    );
}
