import React from "react";

export function UndoIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M24 24h-2a7.008 7.008 0 0 0-7-7h-4.83v6.414L.877 14.121a3 3 0 0 1 0-4.242L10.17.586V7H15a9.01 9.01 0 0 1 9 9ZM8.17 5.414l-5.879 5.879a1 1 0 0 0 0 1.414l5.879 5.879V15H15a8.989 8.989 0 0 1 7 3.349V16a7.008 7.008 0 0 0-7-7H8.17Z"
                data-name="01 align center"
            />
        </svg>
    );
}
