import React from "react";

export function CloudIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M16 23H5.5a5.49 5.49 0 0 1-2.6-10.329A7.915 7.915 0 0 1 2 9a8 8 0 0 1 15.792-1.788A8 8 0 0 1 16 23ZM10 3a6.006 6.006 0 0 0-6 6 5.936 5.936 0 0 0 1.182 3.562l.882 1.193-1.436.371A3.491 3.491 0 0 0 5.5 21H16a6 6 0 0 0 .8-11.938l-.757-.1-.1-.758A6.01 6.01 0 0 0 10 3Z"
                data-name="01 align center"
            />
        </svg>
    );
}
