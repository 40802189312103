import React from "react";

export function MenuBurgerIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M0 11h24v2H0zM0 4h24v2H0zM0 18h24v2H0z" />
            </g>
        </svg>
    );
}
