import React from "react";

export function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M24 13v-2l-21.445.031 4.322-4.324-1.414-1.414L.877 9.879a3 3 0 0 0 0 4.242l4.586 4.586 1.414-1.414-4.262-4.262Z"
                data-name="01 align center"
            />
        </svg>
    );
}
