import React from "react";

export function FilterIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={512}
            viewBox="0 0 24 24"
            width={512}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            {...props}
        >
            <path d="m15 24-6-4.5v-5.12l-8-9V3a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v2.38l-8 9zm-4-5.5 2 1.5v-6.38l8-9V3a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1.62l8 9z" />
        </svg>
    );
}
