import React from "react";

export function HeartIconWithBorder(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="256"
            height="256"
            viewBox="0 0 256 256"
            {...props}
        >
            <path
                fill="#000"
                strokeMiterlimit="10"
                strokeWidth="1"
                d="M45 83.769L6.653 45.422C2.363 41.132 0 35.428 0 29.36s2.363-11.771 6.653-16.062a22.562 22.562 0 0116.062-6.653 22.566 22.566 0 0116.062 6.653L45 19.521l6.223-6.223c4.29-4.291 9.995-6.653 16.063-6.653s11.771 2.363 16.062 6.653S90 23.293 90 29.36s-2.363 11.771-6.653 16.062L45 83.769zM22.715 8.645c-5.533 0-10.735 2.155-14.647 6.067S2 23.827 2 29.36s2.155 10.735 6.067 14.647L45 80.94l36.933-36.933C85.845 40.095 88 34.894 88 29.36s-2.155-10.735-6.067-14.648a20.585 20.585 0 00-14.648-6.067 20.583 20.583 0 00-14.648 6.067L45 22.35l-7.637-7.637a20.578 20.578 0 00-14.648-6.068z"
                transform="matrix(.72 0 0 .72 128 128) matrix(3.89 0 0 3.89 -175.05 -175.05)"
            ></path>
        </svg>
    );
}
