import React from "react";

export function BigBrochureIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21.775}
            height={24.655}
            {...props}
        >
            <path
                data-name="Trac\xE9 18494"
                d="M.525 2.253 10.69.021h.012c.024 0 .048-.009.072-.012H10.997c.026 0 .051.007.076.012h.025L21.25 2.253a.6.6 0 0 1 .525.537V24.1a.527.527 0 0 1-.287.445.9.9 0 0 1-.633.091l-9.968-2.188L.92 24.636a.923.923 0 0 1-.2.021.871.871 0 0 1-.436-.113.527.527 0 0 1-.287-.445V2.789a.6.6 0 0 1 .528-.536Zm19.805.958L11.61 1.3v20.145l8.72 1.914ZM1.445 23.359l8.72-1.914V1.3l-8.72 1.91Z"
            />
            <path
                data-name="Trac\xE9 18495"
                d="m8.75 4.518-5.387.962a1.387 1.387 0 0 1-.243.021.93.93 0 0 1-.854-.405c-.134-.3.139-.6.611-.689l5.387-.962a1.033 1.033 0 0 1 1.1.384c.131.296-.143.605-.614.689Z"
            />
            <path
                data-name="Trac\xE9 18496"
                d="m8.75 7.307-5.387.962a1.388 1.388 0 0 1-.243.021.93.93 0 0 1-.854-.405c-.134-.3.139-.6.611-.689l5.387-.962a1.033 1.033 0 0 1 1.1.384c.131.296-.143.605-.614.689Z"
            />
            <path
                data-name="Trac\xE9 18497"
                d="m8.75 10.097-5.387.962a1.388 1.388 0 0 1-.243.021.93.93 0 0 1-.854-.405c-.134-.3.139-.6.611-.689l5.387-.962a1.033 1.033 0 0 1 1.1.384c.131.296-.143.605-.614.689Z"
            />
            <path
                data-name="Trac\xE9 18498"
                d="m8.75 20.248-5.388.962a1.384 1.384 0 0 1-.243.021 1.266 1.266 0 0 1-.535-.113.531.531 0 0 1-.353-.445V13.31c0-.249.263-.468.645-.537l5.388-.962a1.328 1.328 0 0 1 .778.091.531.531 0 0 1 .353.445v7.369c0 .245-.266.464-.645.532Zm-.747-7.474-4.64.844v6.41l4.64-.7Z"
            />
            <path
                data-name="Trac\xE9 18499"
                d="m13.79 19.175 5.139.962c.45.084.711.393.583.689a.874.874 0 0 1-.814.405 1.261 1.261 0 0 1-.232-.021l-5.139-.962c-.45-.084-.711-.393-.583-.689a.963.963 0 0 1 1.046-.384Z"
            />
            <path
                data-name="Trac\xE9 18500"
                d="M18.698 5.502a1.262 1.262 0 0 1-.232-.021l-5.139-.962c-.45-.084-.711-.393-.583-.689a.963.963 0 0 1 1.046-.384l5.139.962c.45.084.711.393.583.689a.874.874 0 0 1-.814.405Z"
            />
            <path
                data-name="Trac\xE9 18501"
                d="m13.79 6.234 5.139.962c.45.084.711.393.583.689a.874.874 0 0 1-.814.405 1.262 1.262 0 0 1-.232-.021l-5.139-.962c-.45-.084-.711-.393-.583-.689a.963.963 0 0 1 1.046-.384Z"
            />
        </svg>
    );
}
