import React from "react";
import styled from "styled-components";

import "bootstrap/dist/css/bootstrap.min.css";

export type StyledTableBodyTdPropsType = {
    children: React.ReactNode;
};

export const StyledTableBodyTd = styled.tbody<StyledTableBodyTdPropsType>`
    & td {
        font-family: "Segoe UI Regular";
        color: #2b2828;
        font-size: 16px;
    }
`;
