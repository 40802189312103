import React from "react";

export function DisassociateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <g data-name="Groupe 28853">
                <path
                    data-name="Rectangle 10766"
                    fill="transparent"
                    d="M0 0h46v46H0z"
                />
                <g fill="#2b2828">
                    <path
                        data-name="Trac\xE9 21065"
                        d="M33.544 12.524a6.512 6.512 0 0 0-8.837.431l-2.859 2.859a1.28 1.28 0 0 0 1.81 1.81l2.878-2.878a3.966 3.966 0 0 1 5.547-.081 3.846 3.846 0 0 1-.018 5.413l-2.973 2.975a1.28 1.28 0 1 0 1.809 1.811l2.977-2.977a6.41 6.41 0 0 0-.334-9.363Z"
                    />
                    <path
                        data-name="Trac\xE9 21066"
                        d="M13.2 34.219a6.512 6.512 0 0 0 8.837-.431l2.863-2.859a1.28 1.28 0 1 0-1.81-1.81l-2.878 2.878a3.965 3.965 0 0 1-5.547.08 3.846 3.846 0 0 1 .018-5.413l2.974-2.975a1.28 1.28 0 0 0-1.811-1.809l-2.975 2.976a6.41 6.41 0 0 0 .329 9.363Z"
                    />
                    <path
                        data-name="Trac\xE9 21067"
                        d="m26.038 18.897-2.061 2.061a.853.853 0 0 0 0 1.207l.6.6a.853.853 0 0 0 1.207 0l2.115-2.109a1.282 1.282 0 0 0-.069-1.874 1.329 1.329 0 0 0-1.792.115Z"
                    />
                    <path
                        data-name="Trac\xE9 21068"
                        d="M18.474 26.996a1.276 1.276 0 0 0 .442.969 1.33 1.33 0 0 0 1.792-.115l2.061-2.061a.853.853 0 0 0 0-1.207l-.6-.6a.853.853 0 0 0-1.207 0l-2.115 2.104a1.28 1.28 0 0 0-.373.905Z"
                    />
                    <path
                        data-name="Trac\xE9 21069"
                        d="M17.401 17.824a.427.427 0 0 0 .3-.728l-4.266-4.267a.427.427 0 1 0-.6.6l4.264 4.275a.427.427 0 0 0 .3.125Z"
                    />
                    <path
                        data-name="Trac\xE9 21070"
                        d="M18.681 11.424a.427.427 0 0 0-.427.427v4.267a.427.427 0 0 0 .853 0v-4.267a.427.427 0 0 0-.426-.427Z"
                    />
                    <path
                        data-name="Trac\xE9 21071"
                        d="M11.427 18.678a.427.427 0 0 0 .427.427h4.267a.427.427 0 0 0 0-.853h-4.267a.427.427 0 0 0-.427.426Z"
                    />
                    <path
                        data-name="Trac\xE9 21072"
                        d="M29.046 29.043a.427.427 0 0 0 0 .6l4.267 4.275a.427.427 0 1 0 .6-.6l-4.267-4.267a.427.427 0 0 0-.6-.008Z"
                    />
                    <path
                        data-name="Trac\xE9 21073"
                        d="M28.068 30.198a.427.427 0 0 0-.427.427v4.267a.427.427 0 0 0 .853 0v-4.267a.427.427 0 0 0-.426-.427Z"
                    />
                    <path
                        data-name="Trac\xE9 21074"
                        d="M34.895 28.491a.427.427 0 0 0 0-.853h-4.267a.427.427 0 0 0 0 .853Z"
                    />
                </g>
            </g>
        </svg>
    );
}
