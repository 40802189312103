import React from "react";

export function CertificateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <path
                d="M4.817 14.573h6.021V17H4.817ZM16.86 9.715H4.817v2.429H16.86Zm0-4.858H4.817v2.43H16.86Zm3.613 18.755v5.533l-2.409-2.429-2.409 2.429v-5.532a4.859 4.859 0 0 1-2.245-5.455 4.808 4.808 0 0 1 4.654-3.586 4.707 4.707 0 0 1 1.2.172V3.643a1.209 1.209 0 0 0-1.2-1.214H3.613a1.209 1.209 0 0 0-1.2 1.214v20.645h10.834v2.429H0V3.643A3.628 3.628 0 0 1 3.613 0h14.451a3.628 3.628 0 0 1 3.613 3.643v12.608a4.82 4.82 0 0 1-1.2 7.362Zm0-4.182a2.409 2.409 0 1 0-2.409 2.429 2.419 2.419 0 0 0 2.409-2.428Z"
                fill="#2b2828"
                data-name="Groupe 31918"
            />
        </svg>
    );
}
