import React from "react";

export function DisplayIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <g data-name="Groupe 28737" transform="translate(20529 7366)">
                <rect
                    width={46}
                    height={46}
                    rx={6}
                    transform="translate(-20529 -7366)"
                    fill="transparent"
                />
                <path d="M-20511.476-7347.381v-2.191h8.762v2.19Zm-3.285 15.338a1.1 1.1 0 0 1-1.096-1.1v-19.714a1.1 1.1 0 0 1 1.1-1.1h16.424v10.414a7.133 7.133 0 0 1 2.19 1.446v-14.046h-18.618a3.286 3.286 0 0 0-3.286 3.286v19.714a3.286 3.286 0 0 0 3.286 3.286h13.69a7.091 7.091 0 0 1-5.124-2.19Zm19.214 2.139-2.91-2.91a4.967 4.967 0 1 1 1.549-1.549l2.91 2.91Zm-5.524-4.339a2.738 2.738 0 1 0-2.738-2.738 2.738 2.738 0 0 0 2.738 2.738Z" />
            </g>
        </svg>
    );
}
