import React from "react";
import { useTable, usePagination } from "react-table";
import styled from "styled-components";

import { AngleDoubleSmallLeftIcon } from "../UiconsSvgIcons/AngleDoubleSmallLeftIcon";
import { AngleDoubleSmallRightIcon } from "../UiconsSvgIcons/AngleDoubleSmallRightIcon";
import { AngleSmallLeftIcon } from "../UiconsSvgIcons/AngleSmallLeftIcon";
import { AngleSmallRightIcon } from "../UiconsSvgIcons/AngleSmallRightIcon";

import "bootstrap/dist/css/bootstrap.min.css";

import makeData from "./makeData";

function TablePagination({ columns, data }: any) {
    const {
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    }: any = useTable(
        {
            columns,
            data,
            // @ts-ignore
            initialState: { pageIndex: 2 },
        },
        usePagination
    );

    return (
        <div className="d-flex justify-content-end mr-3 pagination">
            <button
                className="btn"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
            >
                <AngleDoubleSmallLeftIcon height={20} width={20} />
            </button>{" "}
            <button
                className="btn"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
            >
                <AngleSmallLeftIcon height={20} width={20} />
            </button>{" "}
            <span className="d-flex align-items-center">
                Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <button
                className="btn"
                onClick={() => nextPage()}
                disabled={!canNextPage}
            >
                <AngleSmallRightIcon height={20} width={20} />
            </button>{" "}
            <button
                className="btn"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
            >
                <AngleDoubleSmallRightIcon height={20} width={20} />
            </button>{" "}
        </div>
    );
}

const Wrapper = styled.div`
    font-family: "Segoe UI semibold";
    font-size: 18px;
`;

export function Pagination() {
    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                columns: [
                    {
                        Header: "First Name",
                        accessor: "firstName",
                    },
                    {
                        Header: "Last Name",
                        accessor: "lastName",
                    },
                ],
            },
            {
                Header: "Info",
                columns: [
                    {
                        Header: "Age",
                        accessor: "age",
                    },
                    {
                        Header: "Visits",
                        accessor: "visits",
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                    },
                    {
                        Header: "Profile Progress",
                        accessor: "progress",
                    },
                ],
            },
        ],
        []
    );

    const data = React.useMemo(() => makeData(100000), []);

    return (
        <Wrapper>
            <TablePagination columns={columns} data={data} />
        </Wrapper>
    );
}
