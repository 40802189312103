import React from "react";

export function FolderIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M21 3h-8.764l-4-2H3a3 3 0 0 0-3 3v19h24V6a3 3 0 0 0-3-3ZM3 3h4.764l4 2H21a1 1 0 0 1 1 1v.881L2 6.994V4a1 1 0 0 1 1-1ZM2 21V8.994l20-.113V21Z"
                data-name="01 align center"
            />
        </svg>
    );
}
