import React from "react";

export function GridIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M4.5 19.5V22H3a1 1 0 0 1-1-1v-1.5h2.5m2-2H0V21a3 3 0 0 0 3 3h3.5v-6.5ZM22 19.5V21a1 1 0 0 1-1 1h-1.5v-2.5H22m2-2h-6.5V24H21a3 3 0 0 0 3-3v-3.5ZM4.5 10.75v2.5H2v-2.5h2.5m2-2H0v6.5h6.5v-6.5ZM22 10.75v2.5h-2.5v-2.5H22m2-2h-6.5v6.5H24v-6.5ZM4.5 2v2.5H2V3a1 1 0 0 1 1-1h1.5m2-2H3a3 3 0 0 0-3 3v3.5h6.5V0ZM13.25 19.5V22h-2.5v-2.5h2.5m2-2h-6.5V24h6.5v-6.5ZM13.25 10.75v2.5h-2.5v-2.5h2.5m2-2h-6.5v6.5h6.5v-6.5ZM13.25 2v2.5h-2.5V2h2.5m2-2h-6.5v6.5h6.5V0ZM21 2a1 1 0 0 1 1 1v1.5h-2.5V2H21m0-2h-3.5v6.5H24V3a3 3 0 0 0-3-3Z" />
            </g>
        </svg>
    );
}
