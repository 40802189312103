import React from "react";

export function PencilIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M22.94 1.06a3.626 3.626 0 0 0-5.124 0L0 18.876V24h5.124L22.94 6.184a3.627 3.627 0 0 0 0-5.124ZM4.3 22H2v-2.3L15.31 6.4l2.3 2.3ZM21.526 4.77l-2.507 2.507-2.295-2.3 2.506-2.503a1.624 1.624 0 0 1 2.3 2.3Z"
                data-name="01 align center"
            />
        </svg>
    );
}
