import React from "react";

export function DotsMenuIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <circle cx={2} cy={12} r={2} />
                <circle cx={12} cy={12} r={2} />
                <circle cx={22} cy={12} r={2} />
            </g>
        </svg>
    );
}
