import React from "react";

export function RefreshIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M12 2a10.032 10.032 0 0 1 7.122 3H15v2h5.143A1.859 1.859 0 0 0 22 5.143V0h-2v3.078A11.982 11.982 0 0 0 0 12h2A10.011 10.011 0 0 1 12 2ZM22 12a9.986 9.986 0 0 1-17.122 7H9v-2H3.857A1.859 1.859 0 0 0 2 18.857V24h2v-3.078A11.982 11.982 0 0 0 24 12Z" />
            </g>
        </svg>
    );
}
