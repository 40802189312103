import React from "react";

export function ShopIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M24 10 21.8 0H2.2L.024 9.783 0 11a3.966 3.966 0 0 0 1 2.618V21a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-7.382A3.966 3.966 0 0 0 24 11Zm-22 .109L3.8 2H7v4h2V2h6v4h2V2h3.2l1.8 8.109V11a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2h-2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2H7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2ZM20 22H4a1 1 0 0 1-1-1v-6.142A3.939 3.939 0 0 0 4 15h1a3.975 3.975 0 0 0 3-1.382A3.975 3.975 0 0 0 11 15h2a3.99 3.99 0 0 0 3-1.357A3.99 3.99 0 0 0 19 15h1a3.939 3.939 0 0 0 1-.142V21a1 1 0 0 1-1 1Z"
                data-name="01 align center"
            />
        </svg>
    );
}
