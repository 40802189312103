import React from "react";

export function CursorFingerIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={512}
            viewBox="0 0 24 24"
            width={512}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            {...props}
        >
            <path d="M12 8.18V3.107A3.082 3.082 0 0 0 9.5.041 3 3 0 0 0 6 3v7.581l-4.121 4.055a3 3 0 0 0 0 4.242L7 24h15V10.18zM20 22H7.828l-4.535-4.536a1 1 0 0 1-.006-1.408L6 13.387V17h2V3a1 1 0 0 1 1.175-.986A1.085 1.085 0 0 1 10 3.107V9.82l10 2z" />
        </svg>
    );
}
