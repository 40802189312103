import React from "react";

export function ShieldExclamationIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={512}
            viewBox="0 0 24 24"
            width={512}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            {...props}
        >
            <path d="M13 15h-2V5h2zm-.207 8.708C14.953 22.84 22 19.428 22 11.991V5.467a2.992 2.992 0 0 0-2.054-2.842L12-.01 4.054 2.625A2.992 2.992 0 0 0 2 5.467v6.524c0 6.563 7.005 10.576 9.153 11.651l.8.4zm6.523-19.185a1 1 0 0 1 .684.944v6.524c0 6.183-6.087 9.112-7.953 9.862C10.159 20.908 4 17.407 4 11.991V5.467a1 1 0 0 1 .684-.944L12 2.1zM13 17h-2v2h2z" />
        </svg>
    );
}
