import React from "react";

export function PeripheralIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M7.5.2H2.6C1.2.2.1 1.3.1 2.7v4.9h4.1v1.6H2.6v1.6h4.9V9.2H5.9V7.5H10V2.6C10 1.3 8.9.2 7.5.2zm.9 5.7H1.8V2.6c0-.5.4-.8.8-.8h4.9c.5 0 .8.4.8.8v3.3zm9 3.3h-3.3c-1.4 0-2.5 1.1-2.5 2.5v8.2h8.2v-8.2c0-1.4-1.1-2.5-2.4-2.5zm.8 9h-4.9v-6.6c0-.5.4-.8.8-.8h3.3c.5 0 .8.4.8.8v6.6zM14.1 4.3h-2.5V2.6h2.5c1.4 0 2.5 1.1 2.5 2.5v2.5H15V5.1c-.1-.5-.4-.8-.9-.8zM6.7 15.7H10v1.6H6.7c-1.4 0-2.5-1.1-2.5-2.5v-2.5h1.6v2.5c.1.6.5.9.9.9zm9.9.9c0 .5-.4.8-.8.8s-.8-.4-.8-.8c0-.5.4-.8.8-.8.4-.1.8.3.8.8z" />
            </g>
        </svg>
    );
}
