import React from "react";

export function AddIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 490"
            height="25"
            width="25"
            {...props}
        >
            <path d="M17.1 490h349.4c9.5 0 17.2-7.7 17.2-17.2v-89.2h89.2c9.5 0 17.1-7.7 17.1-17.1V17.1C490 7.6 482.3 0 472.9 0H123.5c-9.5 0-17.2 7.7-17.2 17.1v89.2H17.1C7.6 106.3 0 114 0 123.5v349.4c0 9.4 7.7 17.1 17.1 17.1zM140.6 34.3h315.1v315.1H140.6V34.3zM34.3 140.6h72v225.9c0 9.5 7.7 17.1 17.2 17.1h225.9v72H34.3v-315z" />
            <path d="M219.5 209H281v61.5c0 9.5 7.7 17.2 17.2 17.2s17.1-7.7 17.1-17.2V209h61.5c9.5 0 17.1-7.7 17.1-17.2s-7.7-17.2-17.1-17.2h-61.5v-61.5c0-9.5-7.7-17.2-17.1-17.2-9.5 0-17.2 7.7-17.2 17.2v61.5h-61.5c-9.5 0-17.1 7.7-17.1 17.2-.1 9.5 7.6 17.2 17.1 17.2z" />
        </svg>
    );
}
