import React from "react";

export function DownloadIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M12.032 19a2.991 2.991 0 0 0 2.122-.878l3.919-3.922-1.414-1.41-3.633 3.634L13 0h-2l.026 16.408-3.62-3.62L5.992 14.2l3.919 3.919a2.992 2.992 0 0 0 2.121.881Z" />
                <path d="M22 16v5a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-5H0v5a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-5Z" />
            </g>
        </svg>
    );
}
