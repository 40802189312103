import React from "react";

export function StarIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24.035}
            height={23.033}
            viewBox="0 0 24.035 23.033"
            {...props}
        >
            <path
                id="star"
                d="M23.972,9.2a1.275,1.275,0,0,0-1.1-.878l-6.937-.63L13.192,1.267a1.277,1.277,0,0,0-2.349,0L8.1,7.688l-6.938.63a1.279,1.279,0,0,0-.725,2.235l5.244,4.6L4.134,21.963a1.275,1.275,0,0,0,1.9,1.38l5.984-3.576L18,23.343a1.277,1.277,0,0,0,1.9-1.38l-1.546-6.811,5.244-4.6A1.278,1.278,0,0,0,23.972,9.2Zm0,0"
                transform="translate(0 -0.492)"
            />
        </svg>
    );
}
