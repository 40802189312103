import React from "react";

export function TrashIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M22 4h-5V2a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H2v2h2v15a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V6h2ZM9 2h6v2H9Zm9 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6h12Z" />
                <path d="M9 10h2v8H9zM13 10h2v8h-2z" />
            </g>
        </svg>
    );
}
