import React from "react";

export function EyeCrossedIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M23.821 11.181a15.736 15.736 0 0 0-4.145-5.44l3.032-3.032-1.415-1.416L18 4.583A11.783 11.783 0 0 0 12 3C4.5 3 1.057 9.261.179 11.181a1.969 1.969 0 0 0 0 1.64 15.736 15.736 0 0 0 4.145 5.44l-3.031 3.032 1.414 1.414L6 19.417A11.783 11.783 0 0 0 12 21c7.5 0 10.943-6.261 11.821-8.181a1.968 1.968 0 0 0 0-1.638ZM2 12.011C2.75 10.366 5.693 5 12 5a9.847 9.847 0 0 1 4.518 1.068l-1.765 1.765a4.992 4.992 0 0 0-6.92 6.92l-2.079 2.079A13.647 13.647 0 0 1 2 12.011ZM15 12a3 3 0 0 1-3 3 2.951 2.951 0 0 1-1.285-.3l3.985-3.985A2.951 2.951 0 0 1 15 12Zm-6 0a3 3 0 0 1 3-3 2.951 2.951 0 0 1 1.285.3L9.3 13.285A2.951 2.951 0 0 1 9 12Zm3 7a9.847 9.847 0 0 1-4.518-1.068l1.765-1.765a4.992 4.992 0 0 0 6.92-6.92l2.078-2.078A13.584 13.584 0 0 1 22 12c-.764 1.657-3.708 7-10 7Z"
                data-name="01 align center"
            />
        </svg>
    );
}
