import React from "react";

export function SignInAltIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path d="M20.905 0H3.1A3.125 3.125 0 0 0 0 3.146V8h2V3.146A1.123 1.123 0 0 1 3.1 2h17.81A1.123 1.123 0 0 1 22 3.146v17.708A1.123 1.123 0 0 1 20.905 22H3.1A1.123 1.123 0 0 1 2 20.854V16H0v4.854A3.125 3.125 0 0 0 3.1 24h17.81A3.125 3.125 0 0 0 24 20.854V3.146A3.125 3.125 0 0 0 20.905 0Z" />
            <path d="M3 13h14.086l-4.3 4.3 1.414 1.418 4.949-4.95a2.5 2.5 0 0 0 0-3.536L14.2 5.282 12.783 6.7l4.3 4.3H3Z" />
        </svg>
    );
}
