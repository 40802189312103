import React from "react";

export function TemplateIcon(
    props: React.SVGProps<SVGSVGElement>
): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M13 2v7H2V3a1 1 0 0 1 1-1h10m2-2H3a3 3 0 0 0-3 3v8h15V0ZM21 2a1 1 0 0 1 1 1v6h-3V2h2m0-2h-4v11h7V3a3 3 0 0 0-3-3ZM5 15v7H3a1 1 0 0 1-1-1v-6h3m2-2H0v8a3 3 0 0 0 3 3h4V13ZM22 15v6a1 1 0 0 1-1 1H11v-7h11m2-2H9v11h12a3 3 0 0 0 3-3v-8Z" />
            </g>
        </svg>
    );
}
