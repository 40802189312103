import React from "react";
import styled from "styled-components";

import {
    StyledButton,
    StyledButtonPropsType as BasePropsType,
} from "../StyledButton";
import { ICONS } from "../../constants";

export type StyledButtonWithIconPropsType = {
    icon: string;
    iconPosition: "right" | "left";
} & BasePropsType;

const ButtonWithIconWrapper = styled(
    StyledButton
)<StyledButtonWithIconPropsType>`
    display: flex;

    align-items: center;
    padding: 12px 10px;
    gap: 10px;

    ${({ iconPosition }: StyledButtonWithIconPropsType) =>
        iconPosition === "right"
            ? `flex-direction: row-reverse; justify-content: space-between;`
            : `flex-direction: unset;justify-content: start;`}

    .icon__clz {
        fill: ${({ variant, outline }: BasePropsType) =>
            outline
                ? variant === "primary"
                    ? "#323333"
                    : variant === "secondary"
                    ? "#626368"
                    : variant === "danger"
                    ? "#E30613"
                    : variant === "reminder"
                    ? "#AE0F0A"
                    : variant === "success"
                    ? "#34C38F"
                    : variant === "info"
                    ? "#0077D7"
                    : variant === "warning"
                    ? "#F1B44C"
                    : variant === "light"
                    ? "#000"
                    : "#F8F8FB"
                : variant === "light"
                ? "#2B2828"
                : "#fff"};
    }

    &:hover {
        .icon__clz {
            fill: ${({ variant, outline }: BasePropsType) =>
                outline
                    ? ["info", "warning"].includes(variant)
                        ? "#000"
                        : variant === "light"
                        ? "#2B2828"
                        : "#fff"
                    : variant === "light"
                    ? "#2B2828"
                    : "#fff"};
        }
    }
`;

export function StyledButtonWithIcon({
    icon,
    children,
    variant,
    outline,
    rounded,
    iconPosition,
    disabled,
    block,
}: StyledButtonWithIconPropsType) {
    const Icon: any = ICONS[icon];

    return (
        <ButtonWithIconWrapper
            variant={variant}
            outline={outline}
            rounded={rounded}
            iconPosition={iconPosition}
            icon={icon}
            disabled={disabled}
            block={block}
        >
            {icon ? (
                <Icon
                    className="icon__clz"
                    fill="white"
                    height={20}
                    width={20}
                />
            ) : null}
            <span>{children}</span>
        </ButtonWithIconWrapper>
    );
}
