import React from "react";

export function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M7.8 20.53a2.99 2.99 0 0 1-2.121-.877L.086 14.061 1.5 12.646l5.593 5.593a1 1 0 0 0 1.414 0L22.5 4.246l1.414 1.414L9.921 19.653a2.99 2.99 0 0 1-2.121.877Z"
                data-name="01 align center"
            />
        </svg>
    );
}
