import React from "react";

export function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M24 11H13V0h-2v11H0v2h11v11h2V13h11v-2z"
                data-name="01 align center"
            />
        </svg>
    );
}
