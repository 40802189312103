import React from "react";

export function InboxIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M21 24H3a3 3 0 0 1-3-3v-9h8v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2h8v9a3 3 0 0 1-3 3ZM2 14v7a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-7h-4a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4ZM2 8h20v2H2zM2 4h20v2H2z" />
            </g>
        </svg>
    );
}
