import React from "react";

export function PortraitIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M21 0H3a3 3 0 0 0-3 3v21h24V3a3 3 0 0 0-3-3ZM7 22v-5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5Zm15 0h-3v-5a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v5H2V3a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1Z" />
                <path d="M12 4a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z" />
            </g>
        </svg>
    );
}
