import React from "react";

export function AssociateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <g data-name="Groupe 28736">
                <path
                    data-name="Rectangle 8227"
                    fill="transparent"
                    d="M0 0h46v46H0z"
                />
                <path
                    data-name="link (3)"
                    d="M16.9 35.316a5.831 5.831 0 0 1-4.153-1.747A5.826 5.826 0 0 1 11 29.199a7 7 0 0 1 2.205-4.689l2.484-2.494a.95.95 0 0 1 1.346 1.34l-2.486 2.5c-2.014 2.014-2.2 4.636-.464 6.376s4.362 1.551 6.375-.462l3.414-3.414a5.147 5.147 0 0 0 1.65-3.417 3.946 3.946 0 0 0-1.188-2.958 4.2 4.2 0 0 0-.889-.682.95.95 0 1 1 .94-1.651 6.1 6.1 0 0 1 1.293.989 5.826 5.826 0 0 1 1.747 4.362 7 7 0 0 1-2.205 4.689l-3.414 3.414a7 7 0 0 1-4.689 2.205 2.026 2.026 0 0 1-.219.009Zm6.32-9a.95.95 0 0 0-.355-1.3 4.2 4.2 0 0 1-.889-.682c-1.741-1.741-1.551-4.362.462-6.375l3.412-3.406c2.013-2.013 4.634-2.2 6.375-.462s1.551 4.362-.464 6.376l-2.486 2.5a.95.95 0 1 0 1.346 1.34l2.484-2.494a7 7 0 0 0 2.205-4.689 5.826 5.826 0 0 0-1.743-4.372 5.828 5.828 0 0 0-4.367-1.747 7 7 0 0 0-4.689 2.205l-3.419 3.414a7 7 0 0 0-2.205 4.689 5.827 5.827 0 0 0 1.743 4.372 6.1 6.1 0 0 0 1.293.989.949.949 0 0 0 1.3-.355Z"
                />
            </g>
        </svg>
    );
}
