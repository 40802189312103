import React from "react";

export function DuplicateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <path
                d="M22 4.145 17.986 0H10a3 3 0 0 0-3 3v1H5a3 3 0 0 0-3 3v17h16v-5h4ZM16 22H4V7a1 1 0 0 1 1-1h2v13h9Zm-7-5V3a1 1 0 0 1 1-1h6v4h4v11Z"
                data-name="01 align center"
            />
        </svg>
    );
}
