import styled from "styled-components";

import { StyledButton, StyledButtonPropsType } from "../StyledButton";

export const StyledDangerButton = styled(StyledButton)<StyledButtonPropsType>`
    background: ${({ outline }: StyledButtonPropsType) =>
        outline ? "transparent" : "#E30613"};
    color: ${({ outline }: StyledButtonPropsType) =>
        outline ? "#E30613" : "#fff"};
    border: ${({ outline }: StyledButtonPropsType) =>
        outline ? "1px solid" : "none"};
    border-color: #e30613;

    &:hover {
        background: #e30613;
        color: #fefefe;
    }
`;
