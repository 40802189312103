import React from "react";

export function PictureIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={512}
            height={512}
            {...props}
        >
            <g data-name="01 align center">
                <path d="M21 0H3a3 3 0 0 0-3 3v21h24V3a3 3 0 0 0-3-3ZM3 2h18a1 1 0 0 1 1 1v17.586L11.121 9.707a3 3 0 0 0-4.242 0L2 14.586V3a1 1 0 0 1 1-1ZM2 17.414l6.293-6.293a1 1 0 0 1 1.414 0L20.586 22H2Z" />
                <path d="M16 10a3 3 0 1 0-3-3 3 3 0 0 0 3 3Zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1Z" />
            </g>
        </svg>
    );
}
