import React from "react";

export function AddFolderIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            height={512}
            viewBox="0 0 24 24"
            width={512}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            {...props}
        >
            <path d="M13 14h3v2h-3v3h-2v-3H8v-2h3v-3h2zm11-8v17H0V4a3 3 0 0 1 3-3h5.236l4 2H21a3 3 0 0 1 3 3zM2 4v3h20V6a1 1 0 0 0-1-1h-9.236l-4-2H3a1 1 0 0 0-1 1zm20 17V9H2v12z" />
        </svg>
    );
}
