import React from "react";

export function CameraIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={121.831}
            height={95.89}
            viewBox="0 0 121.831 95.89"
            {...props}
        >
            <g id="camera" transform="translate(0 -44.8)">
                <g
                    id="Groupe_27777"
                    data-name="Groupe 27777"
                    transform="translate(0 44.8)"
                >
                    <g
                        id="Groupe_27776"
                        data-name="Groupe 27776"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Trac\xE9_21038"
                            data-name="Trac\xE9 21038"
                            d="M117.778,59.739a13.1,13.1,0,0,0-9.612-4.053H88.941v-.232a10.4,10.4,0,0,0-3.127-7.528A10.6,10.6,0,0,0,78.287,44.8H43.544a10.676,10.676,0,0,0-10.77,10.654v.232H13.665a13.1,13.1,0,0,0-9.612,4.053A13.768,13.768,0,0,0,0,69.351v57.673a13.1,13.1,0,0,0,4.053,9.612,13.768,13.768,0,0,0,9.612,4.053h94.5a13.1,13.1,0,0,0,9.612-4.053,13.768,13.768,0,0,0,4.053-9.612V69.351A13.1,13.1,0,0,0,117.778,59.739Zm-1.969,67.285h-.116a7.513,7.513,0,0,1-7.528,7.528h-94.5a7.513,7.513,0,0,1-7.528-7.528V69.351a7.513,7.513,0,0,1,7.528-7.528H36.017A3.114,3.114,0,0,0,39.143,58.7V55.339a4.359,4.359,0,0,1,4.517-4.517H78.287A4.359,4.359,0,0,1,82.8,55.339V58.7a3.114,3.114,0,0,0,3.127,3.127h22.351a7.513,7.513,0,0,1,7.528,7.528Z"
                            transform="translate(0 -44.8)"
                        />
                        <path
                            id="Trac\xE9_21039"
                            data-name="Trac\xE9 21039"
                            d="M140.489,130.8a28.469,28.469,0,1,0,20.151,8.338A28.539,28.539,0,0,0,140.489,130.8Zm15.75,44.355a22.368,22.368,0,0,1-31.5,0,22.139,22.139,0,0,1-6.485-15.75,22.632,22.632,0,0,1,6.485-15.75,22.139,22.139,0,0,1,15.75-6.485,22.631,22.631,0,0,1,15.75,6.485,22.139,22.139,0,0,1,6.485,15.75A21.67,21.67,0,0,1,156.239,175.155Z"
                            transform="translate(-79.573 -105.901)"
                        />
                        <circle
                            id="Ellipse_1283"
                            data-name="Ellipse 1283"
                            cx={5.675}
                            cy={5.675}
                            r={5.675}
                            transform="translate(96.469 24.783)"
                        />
                    </g>
                </g>
                <path
                    id="Trac\xE9_21040"
                    data-name="Trac\xE9 21040"
                    d="M73.975,26.384h-10.3v-10.3a3.047,3.047,0,1,0-6.093,0v10.3h-10.3a3.047,3.047,0,0,0,0,6.093h10.3v10.3a3.047,3.047,0,1,0,6.093,0v-10.3h10.3a3.047,3.047,0,1,0,0-6.093Z"
                    transform="translate(0.285 69.018)"
                />
            </g>
        </svg>
    );
}
