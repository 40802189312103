import * as React from "react";

export function StepIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27747"
                data-name="Groupe 27747"
                transform="translate(18139 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                        transform="translate(-2)"
                    />
                </g>
                <g
                    id="process_1_"
                    data-name="process (1)"
                    transform="translate(-18133 -4325)"
                >
                    <path
                        id="Trac\xE9_9479"
                        data-name="Trac\xE9 9479"
                        d="M22,52.006a2,2,0,0,0,1.98-1.717h3.052l-.37.37.4.4.858-.858a.286.286,0,0,0,0-.4l-.858-.858-.4.4.37.37H23.983A2,2,0,1,0,22,52.006Zm0-3.434A1.431,1.431,0,1,1,20.572,50,1.432,1.432,0,0,1,22,48.572Z"
                        transform="translate(-14.85 -34.838)"
                        fill="#fff"
                    />
                    <path
                        id="Trac\xE9_9480"
                        data-name="Trac\xE9 9480"
                        d="M50,52.006a2,2,0,1,0-2-2A2.005,2.005,0,0,0,50,52.006Zm0-3.434A1.431,1.431,0,1,1,48.572,50,1.432,1.432,0,0,1,50,48.572Z"
                        transform="translate(-34.838 -34.838)"
                        fill="#fff"
                    />
                    <path
                        id="Trac\xE9_9481"
                        data-name="Trac\xE9 9481"
                        d="M8.009,25a2,2,0,0,0-1.98,1.717H5.577a3.577,3.577,0,1,0,0,7.153h.6l-.37.37.4.4.858-.858a.286.286,0,0,0,0-.4l-.858-.858-.4.4.37.37h-.6a3,3,0,1,1,0-6.009h.452A2,2,0,1,0,8.009,25Zm0,3.434A1.431,1.431,0,1,1,9.439,27,1.432,1.432,0,0,1,8.009,28.434Z"
                        transform="translate(-2 -18.419)"
                        fill="#fff"
                    />
                    <path
                        id="Trac\xE9_9482"
                        data-name="Trac\xE9 9482"
                        d="M11.032,2.942l-.4.4.055.055L11,3.717H5.983a2,2,0,1,0,0,.572H11l-.37.37.4.4.858-.858a.286.286,0,0,0,0-.4ZM4,5.434A1.431,1.431,0,1,1,5.434,4,1.432,1.432,0,0,1,4,5.434Z"
                        transform="translate(-2 -2)"
                        fill="#fff"
                    />
                    <path
                        id="Trac\xE9_9483"
                        data-name="Trac\xE9 9483"
                        d="M38.76,7.215a3.2,3.2,0,0,0-3.18-3.5h-1.6a2,2,0,1,0,0,.572h1.6a3,3,0,0,1,0,6.009l-5-.078.37-.37-.4-.4-.858.858a.286.286,0,0,0,0,.4l.858.858.4-.4-.37-.37,5,.078C37.552,10.87,38.76,9.188,38.76,7.215ZM32,5.434A1.431,1.431,0,1,1,33.434,4,1.432,1.432,0,0,1,32,5.434Z"
                        transform="translate(-19.988 -2)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Ellipse_1282"
                    data-name="Ellipse 1282"
                    transform="translate(-18131 -4320)"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth={1}
                >
                    <circle cx={3.5} cy={3.5} r={3.5} stroke="none" />
                    <circle cx={3.5} cy={3.5} r={3} fill="none" />
                </g>
            </g>
        </svg>
    );
}
