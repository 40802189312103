import * as React from "react";

export function ItemIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            viewBox="0 0 30 30"
            {...props}
        >
            <g
                id="Groupe_27741"
                data-name="Groupe 27741"
                transform="translate(18137 4331)"
            >
                <g
                    id="Groupe_27739"
                    data-name="Groupe 27739"
                    transform="translate(-18137 -4331)"
                >
                    <rect
                        id="Rectangle_10647"
                        data-name="Rectangle 10647"
                        width={30}
                        height={30}
                    />
                    <g id="take-away" transform="translate(6 6)">
                        <path
                            id="Trac\xE9_17687"
                            data-name="Trac\xE9 17687"
                            d="M34.434,204.8a.3.3,0,0,0-.3.3v7.228a.3.3,0,1,0,.6,0V205.1A.3.3,0,0,0,34.434,204.8Zm0,0"
                            transform="translate(-32.928 -197.573)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_17688"
                            data-name="Trac\xE9 17688"
                            d="M34.434,443.734a.3.3,0,0,0-.3.3v.6a.3.3,0,1,0,.6,0v-.6A.3.3,0,0,0,34.434,443.734Zm0,0"
                            transform="translate(-32.928 -428.074)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_17689"
                            data-name="Trac\xE9 17689"
                            d="M18.057,6.261a.3.3,0,0,0-.013-.057v0L15.332.177A.3.3,0,0,0,15.276.1c-.007-.007-.014-.012-.021-.018a.3.3,0,0,0-.064-.043L15.162.021a.3.3,0,0,0-.1-.021H3.118a.9.9,0,0,0-.839.568L.021,6.212a.287.287,0,0,0-.011.059A.291.291,0,0,0,0,6.324V17.166a.9.9,0,0,0,.9.9H17.166a.9.9,0,0,0,.9-.9V6.321a.3.3,0,0,0-.012-.06ZM17.3,6.023H13.093l1.98-4.952ZM2.839.792A.3.3,0,0,1,3.118.6H14.613L12.445,6.023H.746ZM.6,17.166V6.625H12.347V17.467H.9a.3.3,0,0,1-.3-.3Zm16.865,0a.3.3,0,0,1-.3.3H12.95V6.625h4.517Zm0,0"
                            transform="translate(0 0)"
                            fill="#fff"
                        />
                        <path
                            id="Trac\xE9_17690"
                            data-name="Trac\xE9 17690"
                            d="M108.294,53.91h4.633a1.7,1.7,0,0,0,1.564-1.031,1.2,1.2,0,0,0-1.107-1.679h-4.633a1.7,1.7,0,0,0-1.564,1.031,1.2,1.2,0,0,0,1.107,1.679Zm-.554-1.442a1.1,1.1,0,0,1,1.01-.666h4.633a.6.6,0,0,1,.554.84,1.1,1.1,0,0,1-1.011.666h-4.633a.6.6,0,0,1-.554-.84Zm0,0"
                            transform="translate(-103.31 -49.392)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
